#error-page .sso-error-message > * {
    text-wrap: pretty;
    word-break: break-word;
}

#error-page .sso-error-message code {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    font-size: inherit;
}
