body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  background: #262a2f;
}

a:not(.ScwAppBarButton-root):not(.ScwAppBarMenu-root):not(.ScwAppBarMenuItem-root):not(.ScwLogo-root):not(
    .ScwButton-root
  ):not(.MuiLink-root):not(.ds-focus):not(.a11y-policy-links*):not(.tournament-tabs*) {
  outline: none !important;
  text-decoration: none;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

a:hover,
a:focus,
select:focus,
button:focus {
  outline: none;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
input:not(.ScwSearchInput-input):not(.ds-focus),
select,
textarea,
button:not(.ScwAppBarButton-root):not(.ScwAppBarMenu-root):not(.ScwAppBarMenuItem-root):not(.ScwLogo-root):not(
    .ScwButton-root
  ):not(.MuiPaginationItem-root):not(.MuiButtonBase-root):not(.MuiLink-root):not(.ds-focus) {
  outline: none !important;
}

h2 {
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 18px;
  margin: 10px 0;
  padding: 0;
  font-weight: 600;
}

[ng-click],
[ui-sref] {
  cursor: pointer;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

input,
textarea,
select {
  /*-moz-appearance: none;
    -webkit-appearance: none;
   */
}

.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  margin-left: -4px;
}

.position-relative {
  position: relative;
}

/*********** Top Part ************/
header {
  margin: 0px;
  padding: 0px;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

.cs_scroll {
  width: 100%;
  float: left;
}

.activity {
  padding: 15px 25px;
  display: block;
}

.activity_scroll {
  height: 225px;
  overflow: auto;
}

.activity_block {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 300;
}

#content {
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  width: 100%;
}

.title_row-flex {
  padding: 10px 0;
  position: relative;
  flex-flow: row nowrap;
  display: flex;
}

.title_row-flex .title_row-flex-left_content {
  flex-grow: 12;
}

.title_row-flex .title_row-flex-right_content {
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  max-width: 360px;
  margin-top: 14px;
}

.title_row-flex .title_row-flex-right_content div.export-wrapper {
  margin-left: 10px;
  margin-top: 4px;
}

.title_row .fa-question-circle {
  font-size: 18px;
}

.select_menu {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
}

.select_menu.text-left {
  text-align: left;
}

.select_menu .btn-default {
  background: #12171d;
  border: 1px solid #4a5159;
  color: #929497;
  /*font-size:16px;*/
}

.select_menu .btn-default:active:hover,
.select_menu .btn-default.active:hover,
.select_menu .open > .dropdown-toggle.btn-default:hover,
.select_menu .btn-default:active:focus,
.select_menu .btn-default.active:focus,
.select_menu .open > .dropdown-toggle.btn-default:focus,
.select_menu .btn-default:active.focus,
.select_menu .btn-default.active.focus,
.select_menu .open > .dropdown-toggle.btn-default.focus {
  background: #12171d;
  color: #ffffff;
}

.select_menu .btn-default:active,
.select_menu .btn-default.active,
.select_menu .open > .dropdown-toggle.btn-default {
  background: #12171d;
  color: #ffffff;
}

.select_menu .nya-bs-select {
  margin: 0;
}

.select_menu .nya-bs-select.btn-group .dropdown-menu {
  background: #12171d;
  border-radius: 0 0 3px 3px;
  box-shadow: none;
  margin-top: -3px;
  border: 1px solid #adadad;
  border-top: none;
  width: 220px;
  overflow: hidden;
}

.select_menu .nya-bs-select.btn-group .dropdown-menu.open {
  max-height: 300px;
  overflow-y: auto;
}

.select_menu .nya-bs-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select_menu .dropdown-menu > li > a {
  color: #ffffff;
  padding: 8px 10px;
}

.select_menu .dropdown-menu > li.active > a {
  background-color: #272b30;
}

.select_menu .dropdown-menu > li > a:hover {
  background: #4a5159;
  color: #ffffff;
}

.select_menu .dropdown-menu > li > a:focus {
  text-decoration: none;
  background-color: #272b30;
}

.select_menu .dropdown-menu .bs-searchbox input {
  background: #272b30;
  border-radius: 0px;
  border: #adadad;
  color: #929497;
}

.select_menu .nya-bs-select.btn-group .dropdown-menu li.no-search-result {
  display: none;
  padding: 3px;
  background: #12171d;
  margin: 0 5px;
}

.select_menu label ~ .nya-bs-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 340px;
}

.course_block {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 2px solid #343e47;
  border-radius: 3px;
  padding: 20px;
  position: relative;
  height: 165px;
  margin-bottom: 30px;
  transition: border-color 1s ease;
}

.course_block .add-course {
  bottom: 50%;
  right: 50%;
  position: absolute;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  font-size: 36px;
}

.course_block:hover {
  border-color: #6b6b6b;
}

.course-description {
  font-style: italic;
  font-size: 14px;
  height: 65px;
}

.mr-1 {
  margin-right: 5px;
}

.mt-1 {
  margin-top: 5px;
}

.block_head {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.block_bar {
  float: right;
}

.block_bar .rate_spin_sm {
  margin-right: 10px;
  margin-top: 2px;
}

span.rate {
  margin-right: 10px;
}

.block_bar .btn {
  background: #ffb700;
  border: none;
  padding: 3px 5px;
  min-width: 27px;
  font-size: 18px;
  line-height: 1;
  float: right;
  color: #1f2831;
}

.block_bar .btn-group .fa-close {
  display: none;
}

.block_bar .btn-group.open .fa-close {
  display: block;
}

.block_bar .btn-group.open .fa-bars {
  display: none;
}

.block_bar .dropdown-menu {
  margin-top: -3px;
  background: #ffb700;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 3px 0 3px 3px;
  padding: 0;
  min-width: 220px;
}

.block_bar .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #ffffff;
}

.block_bar .dropdown-menu > li > a img {
  width: 22px;
  margin-right: 10px;
}

.block_bar .dropdown-menu > li > a:hover {
  background: #1f2831;
}

.progress_block {
  position: relative;
  margin: 15px 0 5px 0;
  padding-right: 40px;
  max-width: 150px;
}

.progress_block span.value {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #ffb700;
  top: -8px;
  text-align: right;
}

.progress_block .progress {
  margin-bottom: 0;
  background: #929497;
}

.progress-bar.info {
  background: #ffb700;
}

.course_logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #343e47;
  border-radius: 0 0 2px 2px;
  padding: 8px 15px;
  min-height: 44px;
}

/*.languageSelect {
	color: white;
	border-radius: 5px;
	font-size: 10px;
	text-align: left;
	float: right;
	margin: -3px 0 0 5px;
}

.languageSelect i {
	font-size: 28px;
	margin: 0px 7px 0px 10px;
	float: left;
	color: white;
}

.languageSelect span {
	display: inline-block;
	text-transform: uppercase;
}*/

.enrol {
  margin-top: 5px;
}

.btn.btn-info.sm.enrol {
  border: none;
  padding-top: 2px;
  padding-bottom: 2px;
  float: right;
}

.trophy {
  color: #ffcc00;
  transition: font-size 0.5s ease;
}

.trophy:hover {
  text-shadow: 0px 0px 2px #ffff00;
  font-size: 20px;
}

.streak {
  color: #f38b00;
  transition: font-size 0.5s ease;
}

.streak:hover {
  text-shadow: 0px 0px 2px #ffab00;
  font-size: 20px;
}

.fa-beat {
  animation: fa-beat 1s ease infinite;
  animation-iteration-count: 5;
}

@keyframes fa-beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

ul.logo_menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

ul.logo_menu li {
  width: auto;
  margin: 0;
  padding: 0 10px;
  float: none;
  display: inline;
}

ul.logo_menu li a {
  opacity: 0.4;
}

ul.logo_menu li a:hover {
  cursor: pointer;
}

ul.logo_menu li a i {
  color: white;
  vertical-align: middle;
  font-size: 28px;
}

ul.logo_menu li a:hover,
ul.logo_menu li a.act {
  opacity: 1;
}

.btn.btn-info.sm {
  font-weight: 900;
  text-transform: uppercase;
  color: #202833;
  font-size: 14px;
  padding: 5px 20px;
  background: #ffb700;
  text-shadow: none;
}

.progress-bar.warning {
  background: #f28b20;
}

.btn.btn-info.sm.dis {
  opacity: 1;
}

.footer_powerups {
  position: fixed;
  bottom: -5px;
  left: 0;
  right: 0;
  z-index: 1;
}

.power_block {
  background: #12171d;
  border: 1px solid #343e47;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

.power_block .col-md-6:first-child {
  border-right: 2px solid #262a2f;
  min-height: 120px;
}

.power_left {
  padding: 20px;
}

.power_left .progress_block {
  padding: 0;
  margin: 10px 0;
}

.power_left .progress-bar.info {
  background: #ffb700;
}

.power_left .progress_block .progress {
  background: #1f2833;
}

.power_left strong {
  font-size: 18px;
}

.lavel {
  width: 100%;
  float: left;
  text-align: left;
  text-transform: uppercase;
}

.power_right {
  padding: 20px;
  text-align: left;
}

.power_right label {
  font-weight: normal;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.rate_star {
  width: 200px;
  float: left;
  height: 30px;
  background: url(/public/images/training/rate.svg) no-repeat;
}

.rate_star.rate5 {
  background-position: left top;
}

.rate_star.rate4 {
  background-position: left -31px;
}

.rate_star.rate3 {
  background-position: left -62px;
}

.rate_star.rate2 {
  background-position: left -93px;
}

.rate_star.rate1 {
  background-position: left -124px;
}

.rate_star.rate0 {
  background-position: left -155px;
}

/*********** Section Part ************/
section {
  margin: 0px;
  padding: 0px;
}

/*********** Footer Part ************/
footer {
  margin: 0px;
  padding: 0px;
}

/*--------------*/
.title_row h2 .glyphicon-question-sign {
  font-size: 12px;
  vertical-align: middle;
  color: #90bd31;
}

.title_row .progress_block {
  margin: 15px 0 0;
}

.title_row .progress {
  height: 5px;
}

.title_row .progress-bar {
  background: #ffffff;
}

.title_row span.value {
  color: #ffffff;
}

.title_row span.rate {
  margin-right: 0;
  margin-top: 5px;
  float: right;
  width: 100%;
  text-align: right;
}

.lng_menu {
  background: #2d343b;
  margin: 10px 0;
  padding: 8px 15px;
  border-radius: 5px;
}

.lang_text {
  width: 100%;
  float: left;
}

.lang_text a {
  color: #fff;
  font-size: 18px;
  opacity: 0.5;
  text-transform: uppercase;
}

.lang_text a:hover {
  opacity: 1;
}

.course-details {
  margin: 8px 15px 0px 15px;
  padding-bottom: 12px;
}

.course-stat {
  padding: 10px;
  margin-bottom: 15px;
}

.module_block {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 10px 35px;
  position: relative;
  margin: 15px 0;
}

.module_block .overlay {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.8;
  background-color: #141319;
  text-align: center;
}

.module_block .overlay .overlay-lock {
  bottom: 50%;
  right: 50%;
  position: absolute;
  transform: translate(50%, 50%);
}

.module_block .overlay .overlay-lock .condition-list {
  list-style-type: disc;
  list-style-position: inside;
}

.module_block h3 {
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.module_block .rate {
  text-align: right;
}

.module_box {
  background: #343e47;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  min-height: 165px;
  margin: 0 0 30px 0;
}

.module_box label {
  font-size: 16px;
  color: #9c9b9b;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
}

.module_box .inner {
  text-align: center;
  padding: 0px 10px;
}

.play-now {
  height: 25px;
  border-radius: 3px;
  background: #4c4c4c;
  position: absolute;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  transition: 0.5s;
}

.play-now:hover {
  background: grey;
}

.module_box .node-progress {
  text-align: center;
  margin-top: -5px;
}

.module_box .node-progress .node-progress-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  margin: 0px 3px;
}

.module_box .icon {
  text-align: center;
  padding: 5px 0;
  min-height: 60px;
}

.module_box .icon img {
  width: 45px;
}

.module_box .icon.glow {
}

.module_box .icon.glow img {
  -webkit-filter: drop-shadow(0px 0px 10px rgba(252, 182, 0, 0.4));
}

.module_box .node {
  text-align: center;
  padding: 5px;
  min-height: 65px;
  background: #2f353c;
  border-radius: 5px;
  margin: 0px -10px;
  border: 1px solid #888;
}

.module_box label i {
  position: absolute;
  right: 0;
  color: #b3f806;
  font-size: 18px;
}

.module_box .node .icon {
  text-align: center;
  padding: 0;
  min-height: 30px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.module_box .node .icon img {
  width: 30px;
}

.xp {
  position: absolute;
  width: 49.5%;
  left: 0;
  background: #3e4850;
  bottom: 0;
  border-radius: 0 0 0 5px;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #e8e8e8;
  font-weight: 800;
}

.price {
  position: absolute;
  width: 49.5%;
  right: 0;
  background: #3e4850;
  bottom: 0;
  border-radius: 0 0 5px 0;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
}

.modal_box {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 2px solid #acacac;
  border-radius: 3px;
  padding: 10px 0px;
  position: relative;
  margin: 0px 0;
}

.modal_box .title_row {
  padding: 20px 35px;
}

.modal_box .title_row.select-course-list {
  padding: 20px 35px 0px 35px;
}

.modal_btn {
  text-align: right;
}

.modal_btn .btn.sm {
  border: none;
  margin: 0 0px;
  border-radius: 6px;
  padding: 4px 10px;
}

.modal_btn .btn.sm.dis {
  margin: 0 0px;
}

.course_row {
  border-bottom: 1px solid #3b424a;
  padding: 15px 35px;
}

.course_row h3 {
  font-weight: 300;
  text-transform: uppercase;
}

.course_row:last-child {
  border: none;
}

.lang_box {
  background: #343e47;
  border: 2px solid #343e47;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

.lang_box {
  cursor: pointer;
}

.lang_box label {
  margin: 0;
  min-height: 20px;
  font-size: 11px;
  vertical-align: middle;
  font-weight: 400;
  cursor: pointer;
}

.lang_box figure {
  padding: 0px 0 0;
  min-height: 36px;
}

.lang_box a {
  color: #ffffff;
  opacity: 0.4;
}

.lang_box:hover {
  border-color: #ffb700;
}

.lang_box a:hover {
  opacity: 1;
}

.lang_box.selected {
  border-color: #ffffff;
}

.lang_box.selected a {
  opacity: 1;
}

.lang_box.act {
  border-color: #ffb700;
}

.lang_box.act a {
  opacity: 1;
}

.training #language {
  overflow: scroll !important;
}

#language .modal-lg {
  max-width: 1170px;
  width: 100%;
}

#displayLanguage {
  overflow: scroll !important;
}

#displayLanguage .modal-lg {
  max-width: 1170px;
  width: 100%;
}

table.leader {
  margin: 0;
  padding: 0;
}

table.leader tr {
  margin: 0;
  padding: 20px 35px;
  border-bottom: 1px solid #343e47;
}

table.leader tr:last-child {
  border: transparent;
}

table.leader tr.active {
  padding-top: 18px;
  padding-bottom: 19px;
  border-bottom: 2px solid #fcb600;
  border-top: 2px solid #fcb600;
}

table.leader tr.active .normal-text {
  font-weight: 600;
}

table.leader tr.active .progress_block span.value {
  opacity: 1;
}

table.manager-metrics.leader tr {
  padding: 10px 25px;
  cursor: default;
}

table.manager-metrics.leader tr.selector:hover {
  transition: 0.75s background;
}

table.manager-metrics.leader .heavy-text {
  font-size: 14px;
  cursor: default;
}

table.manager-metrics.leader .normal-text {
  font-size: 14px;
  cursor: default;
}

table.manager-metrics.leader .normal-text.left-aligned,
table.manager-metrics.leader .heavy-text.left-aligned {
  text-align: left;
}

table.manager-metrics.leader {
  padding: 0 15px;
}

table.manager-metrics.leader td,
table.manager-metrics.leader th {
  padding: 10px 10px;
  text-align: center;
  word-wrap: normal;
}

table.manager-metrics.leader .rank {
  width: 5%;
  min-width: 90px;
}

table.manager-metrics.leader .name {
  width: 10%;
  min-width: 80px;
}

table.manager-metrics.leader .status {
  width: 8%;
}

table.manager-metrics.leader .deadline {
  width: 6%;
}

table.manager-metrics.leader .languages {
  width: 15%;
  min-width: 100px;
}

table.manager-metrics.leader .progress-meter {
  width: 10%;
  min-width: 120px;
}

table.manager-metrics.leader .other-stats {
  width: 9%;
  min-width: 80px;
}

table.manager-metrics.leader .description {
  min-width: 150px;
  width: 15%;
}

table.manager-metrics.leader .uppercase {
  text-transform: uppercase;
}

.heavy-text {
  font-size: 17px;
  font-weight: 800;
  color: #ffffff;
}

.normal-text {
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  width: 6%;
  overflow: hidden;
}

.capitalise-text {
  text-transform: capitalize;
}

.leader td .progress_block {
  margin: 10px 0;
}

.leader td .progress {
  height: 5px;
}

.leader td .progress_block span.value {
  color: #ffffff;
}

table.leader.progress-leader th,
table.leader.points-leader th {
  padding: 15px 15px;
}

table.leader.progress-leader td,
table.leader.points-leader td {
  padding: 15px 15px;
}

table.leader.progress-leader .rank,
table.leader.points-leader .rank {
  width: 8%;
  min-width: 60px;
}

table.leader.progress-leader .name,
table.leader.points-leader .name {
  width: 20%;
}

table.leader.progress-leader .languages {
  width: 20%;
}

table.leader.progress-leader .other-stats {
  width: 12%;
}

table.leader.points-leader .other-stats {
  width: 15%;
}

.rate_block {
  float: right;
}

.rate_block .rate_spin_sm {
  margin-right: 20px;
}

.rate_star_sm {
  width: 120px;
  float: left;
  height: 20px;
  background: url(/public/images/training/rate_sm.svg) no-repeat;
}

.rate_star_sm.rate5 {
  background-position: left top;
}

.rate_star_sm.rate4 {
  background-position: left -24px;
}

.rate_star_sm.rate3 {
  background-position: left -48px;
}

.rate_star_sm.rate2 {
  background-position: left -72px;
}

.rate_star_sm.rate1 {
  background-position: left -95px;
}

.rate_spin_sm {
  width: 64px;
  float: left;
  height: 22px;
  background: url(/public/images/training/star.svg) no-repeat;
}

.rate_spin_sm.rate3 {
  background-position: left top;
}

.rate_spin_sm.rate2 {
  background-position: left -23px;
}

.rate_spin_sm.rate1 {
  background-position: left -45px;
}

.rate_spin_sm.rate0 {
  background-position: left -67px;
}

.dark-star {
  color: #5a5a5a;
}

.paging {
  margin: 0;
}

.paging .pagination {
  margin: 10px;
  border-radius: 0;
}

.paging .pagination > li > a,
.paging .pagination > li > span {
  background: none;
  border: none;
  margin: 0 5px;
  color: #ffffff;
  font-size: 18px;
  opacity: 0.5;
  font-weight: 600;
  padding: 4px 10px;
}

.paging .pagination > li > a:hover {
  opacity: 1;
}

.paging .pagination > .active > a,
.paging .pagination > .active > span,
.paging .pagination > .active > a:hover,
.paging .pagination > .active > span:hover,
.paging .pagination > .active > a:focus,
.paging .pagination > .active > span:focus {
  background: -moz-linear-gradient(272deg, #313b46 0%, #1f2631 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #313b46), color-stop(100%, #1f2631));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(272deg, #313b46 0%, #1f2631 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(272deg, #313b46 0%, #1f2631 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(272deg, #313b46 0%, #1f2631 100%);
  /* ie10+ */
  background: linear-gradient(178deg, #313b46 0%, #1f2631 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#313b46', endColorstr='#1f2631', GradientType=0);
  /* ie6-9 */
  border-radius: 5px;
  opacity: 1;
}

/*----------Shop-----------*/
.shop {
  text-align: right;
  position: relative;
}

.shop span {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #ffb700;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 100%;
  line-height: 20px;
}

.shop-block {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 30px 35px 5px;
  position: relative;
  margin: 15px 0 80px;
}

.shop-block .btn .fa-check {
  float: left;
  margin-top: 3px;
}

.shop-box {
  background: #343e47;
  position: relative;
  padding: 20px 20px 20px 120px;
  border-radius: 5px;
  border: 2px solid #343e47;
  min-height: 170px;
  margin-bottom: 30px;
}

.shop-box figure {
  position: absolute;
  left: 30px;
  top: 25px;
  opacity: 0.5;
  width: 120px;
  line-height: 120px;
}

.shop-box figure img {
  width: 60px;
  margin-bottom: 10px;
}

.shop-box a {
  color: #9c9b9b;
}

.shop-box strong {
  font-size: 18px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
}

.shop-box span {
  display: block;
}

.shop-box p {
  margin-bottom: 15px;
}

.shop-box .btn {
  min-width: 100px;
  border: none;
}

.shop-box .btn-info.sm {
  padding: 2px 10px;
}

.shop-box:hover a,
.shop_box.active a {
  color: #ffffff;
}

.shop-box:hover a figure,
.shop_box.active a figure {
  opacity: 1;
}

.shop-box:hover,
.shop_box.active {
  border-color: #ffb700;
}

.shop-box .btn.btn-info.green {
  background: #90bd31;
}

.shop-box button {
  height: 24px;
}

.shop-box button .fa-shopping-cart {
  display: none;
  font-size: 18px;
}

.shop-box:hover button .fa-shopping-cart,
.shop-box.active button .fa-shopping-cart {
  display: block;
}

.shop-box button em {
  display: block;
  font-style: normal;
}

.shop-box:hover button em,
.shop-box.active button em {
  display: none;
}

.shop-box.act a {
  color: #ffffff;
}

.shop-box.active a {
  color: #ffffff;
}

.shop-box.act figure {
  opacity: 1;
}

.shop-box.act:hover {
  border-color: #343e47;
}

/*-------General CSS Styles--------*/
.dark-block {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 20px 20px;
  position: relative;
  margin: 15px 0;
}

.dark-block .light-block {
  background: #343e47;
  border-radius: 5px;
  padding: 15px 20px;
  position: relative;
  min-height: 80px;
  margin: 0 0 30px 0;
}

.dark-block label {
  font-size: 16px;
  color: #9c9b9b;
  text-transform: uppercase;
  font-weight: 600;
}

.standard-input-box {
  background: #12171d;
  border: 1px solid #4a5159;
  color: #929497;
  font-size: 16px;
  height: 36px;
  vertical-align: middle;
  padding: 0px 5px;
}

.light-block {
}

/*--------------Metrics--------*/
.progress_block span.metrics-xp-value {
  position: absolute;
  font-size: 14px;
  color: #fff;
  top: -8px;
}

.metrics-block {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 35px 35px;
  position: relative;
  margin: 15px 0;
}

.metrics-header {
  background: #2d343b;
  margin: 20px 0;
  padding: 15px 15px;
  border-radius: 5px;
}

.metrics-box {
  background: #343e47;
  border-radius: 5px;
  padding: 15px 20px;
  position: relative;
  min-height: 80px;
  margin: 0 0 30px 0;
}

.metrics-box.badges {
  min-height: 40px;
}

.metrics-box.badges .badges-row {
  background-color: #1a212b;
  height: 80px;
  padding: 0px;
  margin: 10px 0px;
  border-radius: 5px;
}

.metrics-box.badges .badges-row i {
  font-size: 40px;
  line-height: 80px;
}

.badge-image {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.metrics-box label {
  font-size: 16px;
  color: #9c9b9b;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  width: 100%;
}

.metrics-box label i {
  position: absolute;
  right: 0;
  /* color:#b3f806; */
  font-size: 20px;
}

.metrics-box span {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.metrics-box .active-powerups {
  font-size: 24px;
  margin: 0px;
}

.metrics-box .active-powerups .powerups-box {
  padding: 0 5px;
  display: inline-block;
}

.metrics-box .active-powerups .powerups-box img {
  width: 35px;
}

.metrics-box ul.active-badges {
  font-size: 24px;
}

.metrics-box ul.active-badges li {
  float: none;
  display: inline;
  padding: 0 5px;
}

.leaderboard-filter {
  background: #12171d;
  border: 1px solid #4a5159;
  color: #929497;
  font-size: 14px;
  height: 36px;
  vertical-align: middle;
  padding: 0px 5px;
}

.filter-search-icon {
  position: absolute;
  right: 13px;
  top: 2px;
}

.leaderboard-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
}

.fit-content {
  width: fit-content;
}

.leaderboard-heading {
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin: 10px 0;
}

.leaderboard-heading i {
  color: #ffb700;
  position: relative;
}

.player-leaderboard-dropdown {
  display: inline-block;
}

.player-leaderboard-dropdown .btn.btn-green.export-leaderboard {
  padding: 5px 10px;
}

.player-leaderboard-dropdown .export {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.player-leaderboard-dropdown .btn.btn-green.dropdown {
  padding: 5px 10px;
  margin-left: -4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 5px;
  height: 30px;
  position: relative;
}

.player-leaderboard-dropdown .dropdown-menu {
  margin-top: 5px;
  padding: 0px;
  border-radius: 4px;
}

.player-leaderboard-dropdown .dropdown-menu li a:hover {
  color: #929497;
  background-color: #ffffff;
  border-radius: 4px;
}

.player-leaderboard-dropdown .dropdown .caret {
  position: absolute;
  right: 5px;
}

.metrics-progress-block {
  width: 16%;
  max-width: 150px;
  float: left;
  padding: 0 1px;
}

.metrics-progress-block .progress {
  margin: 10px 0;
  height: 10px;
  border-radius: 0px;
}

.metrics-progress-block .progress-bar.progress-bar-empty {
  background: #586571;
}

.metrics-box .list-item {
  padding: 10px 0px;
  margin-bottom: 20px;
}

.metrics-box .accordion {
  width: 10px;
  font-size: 16px;
}

.metrics-box .indent-one {
  position: relative;
  left: 20px;
}

.metrics-box .indent-two {
  position: relative;
  left: 40px;
}

.metrics-box .indent-three {
  position: relative;
  left: 60px;
}

.left-align {
  width: 50%;
  float: left;
}

.right-align {
  width: 50%;
  float: right;
}

/*--------------Defin----------*/
.btn_mission {
  width: 100%;
  float: left;
  text-align: right;
}

.btn.btn-green {
  background: #90bc30;
  font-size: 14px;
  color: #131820;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  padding: 5px 15px;
}

.btn.btn-green:hover {
  background: #9ece35;
}

.btn.btn-yellow {
  background: #ffb700;
  font-size: 14px;
  color: #131820;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  padding: 5px 15px;
}

.btn.btn-yellow:hover {
  background: #ffbb10;
}

.btn.btn-green img {
  width: 20px;
  margin-left: 5px;
}

.btn.btn-grey {
  background: #767f86;
  font-size: 14px;
  color: #131820;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  padding: 5px 15px;
}

.btn.btn-grey:hover {
  background: #96a2ab;
}

.map_block {
  width: 100%;
  float: left;
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 0px 0px 0px;
  position: relative;
  margin: 15px 0 80px;
  position: relative;
  overflow: hidden;
}

.map_block svg {
  position: relative;
  overflow: auto;
}

.map_pin {
  z-index: 9999;
  padding-top: 70px;
  position: relative;
}

.map_pin a.pin {
  position: absolute;
  width: 60px;
  height: 68px;
  background: url(/public/images/training/pin_default.svg) left top no-repeat;
  text-align: center;
  padding-top: 12px;
  background-size: 100% 100%;
}

.map_pin.active a.pin {
  background: url(/public/images/training/pin_act.svg) left top no-repeat;
  width: 60px;
  height: 68px;
  text-align: center;
  padding-top: 12px;
  background-size: 100% 100%;
}

.map_pin.locked a.pin {
  background: url(/public/images/training/pin_lock.svg) left top no-repeat;
  width: 60px;
  height: 68px;
  text-align: center;
  padding-top: 12px;
  background-size: 100% 100%;
}

.map_pin.locked {
}

.map_pin.locked a:after {
  content: '';
  position: absolute;
  width: 22px;
  height: 30px;
  top: 45px;
  left: 60%;
  background: url(/public/images/training/lock.svg) left top no-repeat;
}

a.pin:hover {
  -webkit-filter: drop-shadow(-2px -2px 6px #fff);
  filter: drop-shadow(-2px -2px 6px #fff);
}

.pop_in {
  width: 100%;
  float: left;
  position: relative;
  z-index: 888;
  top: 0px;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #9c9b9b;
}

.pop_in strong {
  display: block;
  color: #fdb713;
  padding: 10px 0;
}

.popover {
  background-color: #343e47;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #9ea0a3;
}

.popover.right > .arrow::after {
  border-right-color: #343e47;
}

.popover.right > .arrow {
  border-right-color: #fff;
}

.popover .close {
  margin-top: -15px;
  color: #858b91;
  opacity: 1;
  margin-right: -10px;
  text-shadow: none;
}

.title_row .rate_spin_sm {
  float: right;
  margin-top: 7px;
}

.map-in {
  width: 1140px;
  overflow-x: auto;
}

.map-in {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  position: relative;
  /*background:url(../images/defend-your-code.svg) no-repeat; background-size:cover;*/
}

.map-in .mapimage {
  width: 100%;
}

.map-in .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

.pin {
  position: absolute;
}

.pin.pin_1 {
  left: 195px;
  top: 70px;
}

.pin.pin_2 {
  left: 460px;
  top: 10px;
}

.pin.pin_3 {
  left: 785px;
  top: 70px;
}

.pin.pin_4 {
  left: 460px;
  top: 240px;
}

.pin.pin_5 {
  left: 790px;
  top: 260px;
}

.pin.pin_6 {
  left: 80px;
  top: 380px;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  min-height: 165px;
  margin: 0 0 30px 0;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.active .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.emptyblocks {
  background: -moz-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f2833), color-stop(100%, #13181f));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(359deg, #1f2833 0%, #13181f 100%);
  /* ie10+ */
  background: #10171d;
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f2833', endColorstr='#13181f', GradientType=1);
  /* ie6-9 */
  border: 1px solid #343e47;
  border-radius: 3px;
  padding: 0px 0px;
  position: relative;
  margin-top: 50px;
  height: 440px;
}

.emptyblocks .emptytext {
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 40px;
}

.courses-info {
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
  padding: 16px;
  margin: 25px;
  display: table-cell;
  width: 50%;
}

.course-item {
  white-space: nowrap;
  font-size: 18px;
  padding: 5px;
  margin: 3px;
  background: #262a2f;
  height: 35px;
}

.course-container {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}

.courses-hr {
  margin-top: 10px;
  border: 1px solid #999;
  width: 100%;
}

.courses-thumbnail {
  display: block;
  margin: 20px 0px 20px 0px;
  line-height: 1.42857;
  background-color: rgb(28, 30, 34);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(12, 13, 14);
  border-image: initial;
  border-radius: 4px;
  transition: border 0.2s ease-in-out 0s;
  padding: 10px 15px;
  overflow: visible;
}

.inviteTeamsTable {
  margin: 5px 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #333e48;
}

.non-sortable-header.non-sortable-header-courses {
  width: 110px;
}

.enrollments {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  /* line-height: 1.42857143; */
  background-color: #1c1e22;
  border: 1px solid #0c0d0e;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.course-list-filter {
  border-radius: 5px !important;
  border: 0 !important;
  padding: 0 7px;
  color: rgb(39, 43, 48);
}

.invite-participants #field {
  background: #111 !important;
  padding: 30px !important;
  border-radius: 5px;
}

.invite-participants .invitee-is-invited {
  height: auto;
  background: #222;
  border: 2px solid #f99b16;
  margin: 5px !important;
}

.invite-participants .invite-indicator {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 16px;
}

.invite-participants .invite-indicator.invitee-remove-warning {
  background: rgba(0, 0, 0, 0);
  font-weight: normal;
  color: red;
}

.invite-participants .search-help-box {
  border: 1px solid orange;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.select-user-box {
  height: auto;
  background: #222;
  border: 2px solid #222;
  margin: 5px !important;
  font-size: 14px;
}

.select-user-box .invitee-displaypic-container {
  float: left;
  width: 45px;
  height: 45px !important;
  overflow: hidden;
  padding: 0 !important;
  margin: 10px;
}

.single-liner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #c8c8c8;
}

.nodevelopers-found {
  display: block;
  line-height: 1.42857143;
  background-color: #1c1e22;
  border: 1px solid #999999;
  border-radius: 4px;
  min-height: 160px;
  position: relative;
}

.nodevelopers-found_content {
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.form-control-courses {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.empty-state-h1 {
  padding-top: 40px;
  font-size: 28px;
}

.empty-state-p {
  font-size: 16px;
  width: 60%;
  margin: 0 auto;
}

.empty-state-btn {
  padding-bottom: 40px;
  margin-top: 15px;
}

.training-back {
  position: absolute;
  top: 18px !important;
  left: -25px;
  padding: 4px 8px 2px;
  font-size: 16pt;
  color: #ffb700;
}

.thumbnail-no-courses {
  background: #333e48;
  display: block;
  padding: 40px;
  margin-bottom: 25px;
  margin-top: 25px;
  line-height: 1.42857143;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  height: 440px;
  font-size: 28px;
}

#invitationTable {
  font-size: 16px;
}

input[type='text']:not(.MuiInputBase-input):disabled {
  background: #dddddd;
}

/**
	Pointsbreakdown Display terminal hacker UI
*/
.training-result-terminal {
  font-family: Inconsolata, monospace;
  color: white;
  /*color: #ce9136;*/

  background-color: black;
  padding: 10px;
  border: 1px solid #dc9935;
  max-width: 800px;
  margin: 50pt auto 0 auto;
  border-radius: 4px;
}

.training-result-terminal .result-header {
  margin-top: 0;
  color: #ce9136;
  font-family: Inconsolata, monospace;
  position: relative;
  width: max-content;
}

.training-result-terminal .btn {
  border-radius: 0px;
  height: 30px;
  /*background: inherit;*/
  background: transparent;
  /* IE compatible*/
  border: 2px solid #ce9136;
  color: #ce9136;
  padding: 2px 20px;
  text-transform: uppercase;
  font-size: 15px;
}

.training-result-terminal .btn-primary:hover {
  background: #ce9136;
  color: #32383e;
}

.training-result-terminal .btn-primary:focus {
  background: #ce9136;
  outline: none;
}

.training-result-terminal .level-progress {
  margin-top: 0;
  color: #ce9136;
  font-family: Inconsolata, monospace;
  position: relative;
  width: max-content;
}

.training-result-terminal .result-header::after {
  /*content: "";*/
  position: absolute;
  /* top: 1px;
		right: -14px;*/
  margin-left: 4px;
  /* Fix for IE*/
  display: inline-block;
  background-color: #606060;
  vertical-align: top;
  width: 10px;
  height: 24px;
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.training-result-terminal .result-body {
  text-align: right;
  color: white;
  background-color: black;
}

.training-result-terminal .result-body tr th {
  text-align: right;
}

.training-result-terminal .result-body tr .border-bottom {
  border-bottom: 1px dashed white;
}

.training-result-terminal .xp-progress {
  padding: 0px 10px;
}

.training-result-terminal .progress-bar-level {
  border: 3px solid #ce9136;
  background-color: #1e1e1e;
  margin-bottom: 10px;
}

.training-result-terminal .progress-bar-level-bar {
  position: relative;
  background-color: #1e1e1e;
}

.training-result-terminal .progress-bar-level-bar::after {
  content: '';
  display: block;
  background-color: #ce9136;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.training-result-terminal .detail-box {
  padding: 5px 0px;
  margin-bottom: 5px;
}

.training-result-terminal .image-container {
  width: fit-content;
  background-color: #3f484e;
}

.training-result-terminal .result-xp-display {
  margin: 0 0 0 15px;
}

.assigned-heading {
  color: #ffb700;
  font-weight: bold;
  font-size: 28px;
  padding-top: 20px;
}

/* End of training.style.scss */

.pointsbreakdown-progress {
  height: 10px;
  border-radius: 2px;
}

.progress-bar-challenge {
  background-color: #016b94;
}

.progress-bar-bonus {
  background-color: #dd972a;
}

.progress-bar-streak {
  background-color: #ed5552;
}

.training-hint-tooltip {
  padding-top: 7px;
}

.training-hint-tooltip button[disabled] {
  pointer-events: none;
}

.training-hint-tooltip + div {
  max-width: 120px;
}

.points-breakdown-modal {
  position: fixed;
  top: 20%;
}

.training {
  margin-top: -18px;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #fff;
  font-size: 14px;
  padding-bottom: 65px;
}

.training .progress {
  border: 0 !important;
}

/* Fixes for the input/edit fields.. */
.training .ui-select-bootstrap .ui-select-choices-row.active > a {
  background: #fff !important;
  color: #fff !important;
}

.training .ui-select-container input {
  border: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none !important;
}

.training .ui-select-match-item {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.assigned-table-head {
  background: rgba(0, 0, 0, 0.6);
}

.assigned-table-head th {
  padding: 8px;
  font-weight: bold;
  font-size: 16px;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.leader td {
  border-bottom: 1px solid #888;
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .training {
    margin-top: 32px;
  }
}

.training .ui-select-bootstrap .ui-select-choices-row > span {
  color: #c8c8c8;
}

.training .text-muted {
  color: #ffffff;
}

.training .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  margin: 5px;
}

.training .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  color: #ffffff;
  opacity: 1;
}

.training .ui-select-multiple.ui-select-bootstrap .ui-select-no-choice.dropdown-menu {
  color: #c8c8c8;
}
.training .ui-select-multiple.ui-select-bootstrap .ui-select-no-choice.dropdown-menu li {
  margin: 5px;
}

.training .pageHeading {
  color: #ffb700 !important;
  font-weight: bold !important;
  font-size: 28px !important;
  margin-bottom: 20px;
}

.training .title_row {
  display: flex;
  justify-content: space-between;
}

.training .title_row .course-management-button_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.training .title_row .course-management-button_actions .btn {
  max-height: 30px;
  margin-left: 10px;
}

.training .title_row .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.training .dueDateDisplay {
  font-size: 12px;
  position: relative;
  float: right;
  margin-top: 10px;
}

.training .btn-link i.glyphicon.glyphicon-remove {
  top: 3px;
  color: #000000;
}

table#breakdown-table tr th {
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
}

.assigned-courses-block {
  border-radius: 3px;
  padding: 0;
  margin: 15px 0;
}

.training .caption {
  margin-top: 10px;
}

.training-dropdown-220 {
  width: 220px;
}

.training-dropdown-220 a.option-content {
  width: 202px;
  margin-left: 8px;
  overflow: hidden;
}

.training-dropdown-280 {
  width: 280px;
}

.training-dropdown-280 a.option-content {
  width: 278px;
  margin-left: 8px;
  overflow: hidden;
}

.select_menu .training-dropdown-100,
.select_menu .training-dropdown-100.nya-bs-select.btn-group .dropdown-menu {
  width: 100px;
}

.training-dropdown-100 .progress-leaderboard-list-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

.training-dropdown-100 a.option-content {
  width: 100px;
  overflow: hidden;
}

.select_menu .training-dropdown-120,
.select_menu .training-dropdown-120.nya-bs-select.btn-group .dropdown-menu {
  width: 120px;
}

.training-dropdown-120 a.option-content {
  width: 120px;
  overflow: hidden;
}

.csv-export-swal .option .export-title {
  font-weight: 600;
}

.csv-export-swal .option .export-description {
  margin-top: 10px;
  font-size: 12px;
}

.select_menu .training-dropdown-160,
.select_menu .training-dropdown-160.nya-bs-select.btn-group .dropdown-menu {
  width: 160px;
}

.select_menu .training-dropdown-200,
.select_menu .training-dropdown-200.nya-bs-select.btn-group .dropdown-menu {
  width: 200px;
}

.training-dropdown-200 a.option-content {
  width: 200px;
  overflow: hidden;
}

/* Manager Metrics Courses Progress Summary */
.manager-metrics-courses-progress-summary {
  clear: both;
  margin-bottom: 40px;
}

.manager-metrics-courses-progress-summary_right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.manager-metrics-courses-progress-summary_right .select_menu {
  margin-left: 10px;
}

.manager-metrics-courses-progress-summary_right .select_menu,
.manager-metrics-courses-progress-summary_right .select_menu button {
  text-transform: capitalize;
}

.manager-metrics-courses-progress-summary .paging .pagination,
.manager-metrics-leaderboard .paging .pagination {
  margin: 0;
  text-align: center;
}

.manager-metrics-courses-progress-summary .paging .pagination > li > a,
.paging .pagination > li > span,
.manager-metrics-leaderboard .paging .pagination > li > a {
  font-size: 14px;
}

/* Manager Metrics Leaderboard */
.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .tab-content_wrapper {
  padding: 10px 15px;
  background-color: #3e444c;
  border: 1px solid #1c1e22;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .tab-content_wrapper .tab-content_inner {
  margin-bottom: 60px;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .tab-content_wrapper .tab-content_inner:last-child {
  margin-bottom: 0;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .manager-metrics-leaderboard-filters {
  min-height: 20px;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .manager-metrics-leaderboard-filters .filters-list {
  float: right;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .leaderboard-heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: flex-end;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .leaderboard-heading .leaderboard-heading_left-content {
  line-height: 20px;
}

.manager-metrics-leaderboard .manager-metrics-leaderboard-tabs .leaderboard-heading .leaderboard-heading_right-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: flex-end;
}

.manager-metrics-leaderboard
  .manager-metrics-leaderboard-tabs
  .leaderboard-heading
  .leaderboard-heading_right-content
  .export-leaderboard {
  margin-left: 10px;
}

.manager-metrics-leaderboard .leaderboard-search-wrapper {
  position: relative;
}

.manager-metrics-leaderboard .leaderboard-search-wrapper span {
  line-height: 32px;
}

/* Smart Invite */

.invite-participants div.disabled,
.invite-participants label.disabled,
.invite-participants p.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.course-management_invite-participants {
  margin: 10px 0;
}

.smart-invite_filter-content .ui-select-container,
.smart-invite_filter-content .ui-select-container ul {
  width: 100%;
  display: block;
  padding: 0;
}

.block-content {
  background-color: #000;
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.block-content hr {
  border-top: 1px solid #999999;
}

.block-content .introduction {
  margin-bottom: 20px;
}

.block-content h2 {
  color: #ffb700 !important;
  font-weight: bold !important;
  font-size: 28px !important;
  margin-bottom: 20px;
}

.block-content h3 {
  color: #ffb700 !important;
  font-weight: bold !important;
  font-size: 20px !important;
  margin-bottom: 20px;
}

.block-content h4 {
  font-size: 16px;
}

.block-content .multi-select-component_heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.block-content .select2-result-label .pull-left {
  margin: 4px 8px 5px 5px;
}
.block-content .select2-result-label .pull-left .glyphicon {
  font-size: 30px;
}

.block-content .select-n-clear-all-searched-users-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
}

.block-content .select-n-clear-all-searched-users-wrapper .left-content p {
  margin: 0;
  line-height: 26px;
  height: 100%;
}

.block-content .select-n-clear-all-searched-users-wrapper .right-content {
  position: relative;
}

.block-content .select-n-clear-all-searched-users_buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.block-content .select-n-clear-all-searched-users_buttons button:first-child {
  margin-right: 10px;
}

.block-content .select-n-clear-all-searched-users_message {
  margin-top: 10px;
  color: #ffb700;
}

.block-content .select-n-clear-all-searched-users .selected-users {
  margin-top: 20px;
}

.block-content .invite-participants_more-invites .introduction .heading {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-end;
}
.block-content .invite-participants_more-invites .introduction .heading .heading_subtext {
  position: relative;
  min-width: 600px;
}
.block-content .invite-participants_more-invites .introduction .heading .heading_subtext p {
  position: absolute;
  bottom: 8px;
  left: 10px;
  width: 100%;
}

.block-content .content .control-label {
  margin-bottom: 10px;
}

.block-content .content .smart-invite_all-organization .content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.block-content .content .smart-invite_all-organization .introduction {
  max-width: 80%;
  color: #999999;
}

.block-content .content .invite-participants_more-invites_filter {
  margin-bottom: 30px;
}

.block-content .content .invite-participants_more-invites .searched-users-found {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.block-content .content .invite-participants_more-invites .searched-users-found .searched-users-found_invitee {
  width: 50%;
}

.block-content .content .invite-participants_more-invites .searched-users-found .paging {
  width: 100%;
}

.block-content .content .invite-participants_more-invites .searched-users-found .course-thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #000;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: box-shadow 0.2s ease-in-out;
}

.block-content .content .invite-participants_more-invites_filter .content-label-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.block-content .content .smart-invite_all-organization_company-admin-superceeded {
  background-color: #006b94;
  color: #fff;
  padding: 5px 0;
  text-align: center;
}
