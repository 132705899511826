.collapse-text-toggle {
    font-size: 0.9em;
    color: #666666;
    cursor: pointer;
}
.collapse-text-toggle:hover {
    color: #222222;
}
.collapse-text-toggle:before {
    content: '\00a0(';
}
.collapse-text-toggle:after {
    content: ')';
}