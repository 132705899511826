.metrics-box .vertical-progress {
  display: inline-block;
  position: relative;
  width: 4px;
  bottom: 6px;
  border: 1px solid white;
  height: 40px;
  background-color: #272b30;
  vertical-align: bottom;
  opacity: 1;
  animation-name: growHeight;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
}
@keyframes growHeight {
  0% {
    /* opacity: 0; */
    height: 0px;
  }
  100% {
    /* opacity: 1; */
    height: 40px;
  }
}
.metrics-box .vertical-progress-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
}

.leader-block {
  margin: 10px 0px;
}
