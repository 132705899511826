@font-face {
  font-family: 'scw-devicon';
  src:  url('fonts/scw-devicon.eot?7vfwjk');
  src:  url('fonts/scw-devicon.eot?7vfwjk#iefix') format('embedded-opentype'),
    url('fonts/scw-devicon.ttf?7vfwjk') format('truetype'),
    url('fonts/scw-devicon.woff?7vfwjk') format('woff'),
    url('fonts/scw-devicon.svg?7vfwjk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='scw-devicon-'],
[class*=' scw-devicon-'] {
  font-family: 'scw-devicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scw-devicon-ibm-plain:before {
  content: '\e900';
}
.scw-devicon-r-plain:before {
  content: '\e901';
}
.scw-devicon-powershell-plain:before {
  content: '\e902';
}
.scw-devicon-sap-plain:before {
  content: '\e903';
}
