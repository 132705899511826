@font-face {
  font-family: 'scw-md';
  src: url('fonts/scw-md.eot?3fsayv');
  src:
    url('fonts/scw-md.eot?3fsayv#iefix') format('embedded-opentype'),
    url('fonts/scw-md.ttf?3fsayv') format('truetype'),
    url('fonts/scw-md.woff?3fsayv') format('woff'),
    url('fonts/scw-md.svg?3fsayv#scw-md') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'scw-sm';
  src: url('fonts/scw-sm.eot?htn1e3');
  src:
    url('fonts/scw-sm.eot?htn1e3#iefix') format('embedded-opentype'),
    url('fonts/scw-sm.ttf?htn1e3') format('truetype'),
    url('fonts/scw-sm.woff?htn1e3') format('woff'),
    url('fonts/scw-sm.svg?htn1e3#scw-sm') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'scw-lg';
  src: url('fonts/scw-lg.eot?wv2gfg');
  src:
    url('fonts/scw-lg.eot?wv2gfg#iefix') format('embedded-opentype'),
    url('fonts/scw-lg.ttf?wv2gfg') format('truetype'),
    url('fonts/scw-lg.woff?wv2gfg') format('woff'),
    url('fonts/scw-lg.svg?wv2gfg#scw-lg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-md'],
[class*=' icon-md'] {
  font-family: 'scw-md' !important;
}

[class^='icon-sm'],
[class*=' icon-sm'] {
  font-family: 'scw-sm' !important;
}

[class^='icon-lg'],
[class*=' icon-lg'] {
  font-family: 'scw-lg' !important;
}

.icon-sm-arrow-dropdown:before {
  content: '\e900';
}
.icon-lg-ask:before {
  content: '\e900';
}
.icon-lg-documentation:before {
  content: '\e901';
}
.icon-lg-report-bug:before {
  content: '\e902';
}
.icon-lg-support:before {
  content: '\e903';
}
.icon-lg-support-ticket:before {
  content: '\e904';
}
.icon-lg-news:before {
  content: '\e906';
}
.icon-lg-learn:before {
  content: '\e905';
}
.icon-lg-support:before {
  content: '\e903';
}
.icon-lg-support-ticket:before {
  content: '\e904';
}
.icon-md-admin:before {
  content: '\e900';
}
.icon-md-arrow-down:before {
  content: '\e901';
}
.icon-md-arrow-next:before {
  content: '\e902';
}
.icon-md-assessments:before {
  content: '\e903';
}
.icon-md-course-manager:before {
  content: '\e904';
}
.icon-md-course-metrics:before {
  content: '\e905';
}
.icon-md-courses:before {
  content: '\e906';
}
.icon-md-dashboard:before {
  content: '\e907';
}
.icon-md-languages:before {
  content: '\e908';
}
.icon-md-leaderboard:before {
  content: '\e909';
}
.icon-md-metrics:before {
  content: '\e90a';
}
.icon-md-mission-control:before {
  content: '\e90b';
}
.icon-md-my-courses:before {
  content: '\e90c';
}
.icon-md-my-statistics:before {
  content: '\e90d';
}
.icon-md-resources:before {
  content: '\e90e';
}
.icon-smd-ensei:before {
  content: '\e90f';
}
.icon-md-support:before {
  content: '\e910';
}
.icon-md-training:before {
  content: '\e911';
}
.icon-md-training-metrics:before {
  content: '\e912';
}
.icon-md-trophy:before {
  content: '\e913';
}
